import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tabs from "../components/Tabs"
import Formlabs from "./portfolio/Formlabs"
import Hobby from "./portfolio/Hobby"
import WebApi from "./portfolio/WebApi"
import "../styles/modularscale.scss"

const PORTFOLIO_ITEMS = [
  { label: "Formlabs", component: Formlabs },
  { label: "Web & API", component: WebApi },
  { label: "Hobby", component: Hobby },
]

const PortfolioPage = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const Component = PORTFOLIO_ITEMS[activeIndex].component
  return (
    <Layout>
      <SEO title="Portfolio" />
      <Tabs
        items={PORTFOLIO_ITEMS}
        activeIndex={activeIndex}
        handleSetIndex={setActiveIndex}
      />
      <Component />
    </Layout>
  )
}

export default PortfolioPage
