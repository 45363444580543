import React from "react"
const Tab = ({ item, handleClick, active }) => (
  <button
    className={`tab__button tab__button--${active ? "active" : "inactive"}`}
    onClick={handleClick}
  >
    {item.label}
  </button>
)

export default Tab
