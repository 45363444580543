import React from "react"

import "./tabs.scss"
import Tab from "./Tab"

const Tabs = ({ items, activeIndex, handleSetIndex }) => {
  return (
    <div className="tabs">
      <div className="tabs__items">
        {items.map((item, index) => (
          <Tab
            key={item.label}
            item={item}
            active={index === activeIndex}
            handleClick={() => handleSetIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default Tabs
